<svelte:options tag="cnrl-search-form" />

<script lang="ts">
import { onMount } from "svelte";

  import { apiSearch } from "./api";
  let searchTerm = "";
  let searchResults = [];
  let timeout;

  const getData = async () => {
    const result = await apiSearch({ terms: searchTerm });
    searchResults = result !== undefined ? [...result] : [];
  };
  const handleChange = () => {
    if (searchTerm.length > 0) {
      updateHistoryState();
      window.clearTimeout(timeout);
      timeout = window.setTimeout(() => getData(), 1000);
    }
  };

  const handleKeyPress = (location: string) => {
    window.location.href = location;
  }

  const updateHistoryState = () => {
    const pathParts = searchTerm.split(" ");
    pathParts.unshift("/search");
    const pathString = pathParts.join("/");
    // window.location.pathname = pathParts.join("/");
    const stateObject = { url: pathString };
    window.history.pushState(stateObject, '', pathString);
    // console.log(pathString)
  }

  $: hasResults = searchResults.length > 0;

  // $: {
  //   console.log("Search results updated: ", searchResults);
  // }

  const stripSearch = () => {
    const parts = window.location.pathname.split("/");
    // console.log("parts: ", parts);
    const remIdx = parts.indexOf("search");
    // console.log("index of search: ", remIdx);
    const retVal = remIdx > -1 ? parts.splice(remIdx + 1) : parts
    // console.log("stripSearch", retVal)
    return retVal;
  }

  const keydownHandler = (e: KeyboardEvent) => {
    const { key }  = e
    if (key === "Enter") {
      handleChange();
    }
  }

  const clickHandler = (e: MouseEvent) => {
    const newEvt = new KeyboardEvent("keydown", { key: "Enter" });
    keydownHandler(newEvt);
  }

  onMount(() => {
    window.addEventListener("load", () => {
      let arr = stripSearch();
      searchTerm = arr.join(" ");
      // console.log("updated search term", searchTerm);
      handleChange();
    })
  })
</script>
<main>
  <div class="box-container">
    <input
      bind:value={searchTerm}
      on:keydown={keydownHandler}
      placeholder="Start Typing..."
    />
    <button on:click={clickHandler} id="button-desktop" type="submit" class="search-button">
      Search
    </button>
    <a href="#search" id="button-mobile" type="submit" class="search-button">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.455 13.7133L22 20.2675L20.2675 22L13.7133 15.455C12.2833 16.5275 10.505 17.16 8.58 17.16C3.84083 17.16 0 13.3192 0 8.58C0 3.84083 3.84083 0 8.58 0C13.3192 0 17.16 3.84083 17.16 8.58C17.16 10.505 16.5275 12.2833 15.455 13.7133ZM8.58 14.7033C11.9625 14.7033 14.7033 11.9625 14.7033 8.57083C14.7033 5.17917 11.9625 2.43833 8.58 2.43833C5.1975 2.43833 2.4475 5.1975 2.4475 8.58C2.4475 11.9625 5.18833 14.7125 8.58 14.7125V14.7033Z"
          fill="white"
        />
      </svg>
    </a>
  </div>
  {#if hasResults }
    <div class="results-menu">
      <ul>
        {#each searchResults as item}
          <li>
            <a class="result-link" href={`${item.url}`}>{@html item.title}</a>
          </li>
        {/each}
      </ul>
    </div>
  {/if}
</main>

<style>
  .box-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100px;
    background: transparent;
  }
  input {
    padding: 15px;
    max-width: 470px;
    height: 16px;
    width: 100%;
    border-radius: 5px 0 0 5px;
    border-width: 1px;
    font-family: "forma-djr-micro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: black;
  }
  button {
    font-family: "forma-djr-micro";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    height: 48px;
    width: 81px;
    background: black;
    color: white;
    border-radius: 0 5px 5px 0;
    border-width: 0px;
  }
  #button-mobile {
    display: none;
    height: 38px;
    width: 38px;
    background: black;
    color: white;
    border-radius: 0 5px 5px 0;
    border-width: 0px;
  }
  /* svg#close-icon {
    margin-left: 20px;
  } */
  .results-menu {
    bottom: 19px;
    width: 100%;
    margin: 0 auto 0 auto;
    background: white;
    z-index: 9999;
  }
  .results-menu ul {
    list-style: none;
    padding: 10px;
  }
  .result-link {
    font-family: "forma-djr-micro";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */

    /* Design/Blue */

    color: #007cc3;
  }
  @media screen and (max-width: 1079px) {
    .box-container {
      height: 68px;
    }
    input {
      height: 18px;
      padding: 10px;
      font-family: "forma-djr-micro";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
    }
    #button-desktop {
      display: none;
    }
    #button-mobile {
      display: grid;
      place-content: center;
    }
    /* svg#close-icon {
      display: none;
    } */
    input {
      max-width: 60vw;
    }
    .results-menu {
      bottom: 0px
    }
  }
  @media screen and (max-width: 1079px) {
    .results-menu {
      width: 100vw;
    }
  }
</style>
