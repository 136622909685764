import { DOMAIN, API_PATH } from './constants';
const DEFAULT_POST = {
    img: '',
    title: '',
    content: '',
    slug: ''
};
const apiUrl = DOMAIN + API_PATH;
const api = async (params) => {
    const { slug, resource } = params;
    const queryPath = `${resource}?slug=`;
    const queryVar = apiUrl + queryPath + slug;
    const request = await fetch(queryVar);
    if (request.ok) {
        const reqData = await request.json();
        const data = reqData.length > 0 ? reqData[0] : DEFAULT_POST;
        const post = {
            img: DOMAIN + data.fimg_url || '',
            title: data.title.rendered || '',
            content: data.content.rendered || '',
            slug: data.slug || ''
        };
        return post;
    }
    else {
        return DEFAULT_POST;
    }
};
const apiSearch = async (params) => {
    const terms = params.terms.split(' ');
    const termString = terms.join('+');
    const queryPath = `search?search=${termString}`;
    const queryVar = apiUrl + queryPath;
    const request = await fetch(queryVar);
    if (request.ok) {
        const reqData = await request.json();
        // const data = reqData.length > 0 ? reqData[0] : undefined;
        return reqData;
    }
    else {
        return undefined;
    }
};
export { api, apiSearch };
