<svelte:options tag="cnrl-stock-quote" />
<script lang="ts">
import { onMount } from "svelte";

const stockObject = {
    tsx: {
        symbol: "CNQ",
        exchange: "TSX Exchange",
        compname: "CANADIAN NATURAL RESOURCES LTD",
        date: "Oct 11, 2022",
        time: "16:15 ET",
        open: "72.80",
        high: "74.17",
        low: "72.21",
        last: "72.86",
        tick: "Down",
        pclose: "75.36",
        price_change: "-2.50",
        per_change: "-3.317%",
        volume: "16,127,981",
        yearlow: "N/A",
        yearhigh: "N/A",
        bidprice: "72.71",
        askprice: "73.15",
        bidsize: "100",
        asksize: "100",
        div: "3.00",
        div_yield: "4.12%"
    },
    nyse: {
        symbol: "CNQ",
        exchange: "New York Stock Exchange",
        compname: "CANADIAN NATURAL RESOURCES LTD",
        date: "Oct 11, 2022",
        time: "16:00 ET",
        open: "52.52",
        high: "54.05",
        low: "52.19",
        last: "52.79",
        tick: "Down",
        pclose: "53.71",
        price_change: "-0.92",
        per_change: "-1.713%",
        volume: "2,778,676",
        yearlow: "N/A",
        yearhigh: "N/A",
        bidprice: "N/A",
        askprice: "N/A",
        bidsize: "N/A",
        asksize: "N/A",
        div: "0.00",
        div_yield: "0.00%"
    }
}


  const basePath = 'https://cnrl.jh.nfweb.ca';
  const apiUrl = basePath + '/wp/wp-admin/admin-ajax.php';
  const queryPath = '?action=stock_quote';

  const getQuote = async () => {
    const reqPath = apiUrl + queryPath;
    const request = await fetch(reqPath);
    const data = await request.json();
    // console.log('quote data: ',  data);
  }

  onMount(() => {
    getQuote();
  })

</script>
<div>
    <p>stock</p>
</div>