<svelte:options tag="cnrl-case-study-modal" />

<script lang="ts">
  import { onMount } from "svelte";
  import { api } from "./api";

  // import Modal from './Modal.svelte'

  let showModal = false;

  let study;
  export let slug = "";

  const getStudy = async (slug: string) => {
    const resource = "case-studies";
    const data = await api({ slug, resource });
    study = data;
  };

  onMount(() => {
    getStudy(slug);
  });

  // $: {
  //   console.log("study changed to ", study);
  // }
</script>

<main>
  <div on:click={() => (showModal = true)} on:keydown={() => (showModal = true)}>
    <slot name="anchor" />
  </div>
  {#if showModal}
    <nf-modal on:close={() => (showModal = false)}>
      <div class="modal-container">
        <div class="modal-header">
          <img src={study.img} alt="" />
          <div class="overlay" />
          <div class="study-text"><p>Case Study</p></div>
          <h1 class="study-header">{@html study.title}</h1>
        </div>
        <div class="modal-content">
          <style>
            .wp-block-image {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          </style>
          {@html study.content}
        </div>
      </div>
    </nf-modal>
  {/if}
</main>

<style>
  /* ::slotted(*) {
    font-family: 'museo', arial, sans-serif;
  } */
  .modal-container {
    background-color: white;
    min-height: 95vh;
  }
  .modal-header {
    width: 100%;
    height: 398px;
  }
  .study-text {
    padding: 0px 10px;
  }
  .modal-header .study-text {
    position: absolute;
    width: auto;
    height: 38px;
    left: 8%;
    top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* Design/Horiz Blue Gradient */

    background: linear-gradient(90deg, #005d92 0%, #007cc3 100%);
    border-radius: 5px;
    z-index: 33;
  }

  .modal-header .study-text p {
    padding: 0;
    margin: 0;
    color: white;
    font-family: "forma-djr-micro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */

    /* Design/White */
  }

  .modal-header img {
    position: relative;
    top: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .modal-header .study-header {
    position: absolute;
    width: 710px;
    /* height: 56px; */
    left: 8%;
    top: 115px;
    margin: 0;

    /* Headers/CaseStudyHeader */

    font-family: "forma-djr-micro";
    font-style: normal;
    font-weight: 500;
    font-size: 58px;
    line-height: 72px;
    /* or 124% */

    display: flex;
    align-items: center;

    /* Design/White */

    color: #ffffff;
    z-index: 33;
  }
  .modal-header .overlay {
    background: linear-gradient(
      92deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 30;
    top: -406px;
  }
  .modal-content {
    background: white;
    max-width: 1025px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    padding-bottom: 50px;
  }
  nf-modal::slotted(a[slot="anchor"]) {
    font-family: "open-sans" sans-serif;
  }
  nf-modal::part(modal) {
    position: relative;
    top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    max-width: 1512px;
    min-height: 90vh;
  }
  ::slotted(div[slot="content"]) {
    position: relative;
    top: 30px;
    padding: 35px;
    background: #ffffff;
    width: 100%;
    max-width: 1512px;
    margin-left: auto;
    margin-right: auto;
    height: fit-content;
  }
  ::slotted(h2) {
    margin: 0 0 10px 0;
    font-family: "museo", arial, sans-serif;
    font-size: 38px;
    line-height: 42px;
    color: #fff;
  }
  ::slotted(h3) {
    padding: 0;
    margin: 0 0 25px 0;
    font-family: "museo", arial, sans-serif;
    font-style: italic;
    font-size: 24px;
    line-height: 28px;
    color: #566909;
    background: none;
  }
  ::slotted(li) {
    position: relative;
    margin: 0;
    padding: 15px 0 15px 50px;
    overflow: hidden;
  }
  @media screen and (max-width: 710px) {
    .modal-header .study-header {
      width: 85%;
      font-size: 45px;
      line-height: 50px;
    }
  }
  @media screen and (min-width: 767px) {
    nf-modal::part(modal) {
      /* max-width: 1512px; */
    }
  }
  @media screen and (max-width: 767px) {
    nf-modal::part(modal) {
      width: 98vw;
      /* max-width: 100%; */
      /* margin-left: 0; */
    }
  }
</style>
