<svelte:options tag="cnrl-search" />

<script lang="ts">
  import { apiSearch } from "./api";
  let searchTerm = "";
  let searchResults = [];
  let timeout;
  export let visible = "false";

  const getData = async () => {
    const result = await apiSearch({ terms: searchTerm });
    searchResults = result !== undefined ? [...result] : [];
  };
  const handleChange = () => {
    if (searchTerm.length > 0) {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(() => getData(), 1000);
    }
  };

  const handleSearchButton = () => {
    window.location.href = "/search/" + searchTerm.split(" ").join("+");
  }

  const handleClose = () => {
    visible = false.toString();
  };

  const keydownHandler = (e: KeyboardEvent) => {
    const { key }  = e
    if (key === "Enter") {
      handleSearchButton()
    }
  }

  $: hasResults = searchResults.length > 0;

  // $: {
  //   console.log("Search results updated: ", searchResults);
  // }
</script>

{#if visible === "true"}
  <main>
    <div class="box-container">
      <input
        on:keydown={keydownHandler}
        bind:value={searchTerm}
        placeholder="Start Typing..."
      />
      <button on:click={handleSearchButton} id="button-desktop" type="submit" class="search-button">
        Search
      </button>
      <a on:click={handleSearchButton} on:keydown={handleSearchButton} href="#search" id="button-mobile" type="submit" class="search-button">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.455 13.7133L22 20.2675L20.2675 22L13.7133 15.455C12.2833 16.5275 10.505 17.16 8.58 17.16C3.84083 17.16 0 13.3192 0 8.58C0 3.84083 3.84083 0 8.58 0C13.3192 0 17.16 3.84083 17.16 8.58C17.16 10.505 16.5275 12.2833 15.455 13.7133ZM8.58 14.7033C11.9625 14.7033 14.7033 11.9625 14.7033 8.57083C14.7033 5.17917 11.9625 2.43833 8.58 2.43833C5.1975 2.43833 2.4475 5.1975 2.4475 8.58C2.4475 11.9625 5.18833 14.7125 8.58 14.7125V14.7033Z"
            fill="white"
          />
        </svg>
      </a>
      <svg
        id="close-icon"
        on:click={() => {
          handleClose();
        }}
        on:keydown={() => {
          handleClose();
        }}
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.5 1.888L18.612 0L10.5433 8.06867L2.388 0L0.5 1.888L8.56867 10.0427L0.5 18.112L2.388 20L10.5427 11.9313L18.6113 20L20.4993 18.112L12.4307 10.0433L20.5 1.888Z"
          fill="white"
        />
      </svg>
    </div>
    {#if hasResults && searchTerm.length > 0}
      <div class="results-menu">
        <ul>
          {#each searchResults as item}
            <li>
              <a class="result-link" href={`${item.url}`}>{@html item.title}</a>
            </li>
          {/each}
        </ul>
      </div>
    {/if}
  </main>
{/if}

<style>
  .box-container {
    display: flex;
    /* width: 100%; */
    align-items: center;
    justify-content: center;
    height: 100px;
    background: #005d92;
  }
  input {
    padding: 15px;
    max-width: 470px;
    height: 18px;
    width: 100%;
    border-radius: 5px 0 0 5px;
    border-width: 0px;
    font-family: "forma-djr-micro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: black;
  }
  button {
    font-family: "forma-djr-micro";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    height: 48px;
    width: 81px;
    background: black;
    color: white;
    border-radius: 0 5px 5px 0;
    border-width: 0px;
  }
  #button-mobile {
    display: none;
    height: 38px;
    width: 38px;
    background: black;
    color: white;
    border-radius: 0 5px 5px 0;
    border-width: 0px;
  }
  svg#close-icon {
    margin-left: 20px;
    cursor: pointer;
  }
  .results-menu {
    position: relative;
    bottom: 19px;
    max-width: 622px;
    width: 100%;
    margin: 0 auto 0 auto;
    background: white;
    border: 1px solid #D1D9E6;
    z-index: 9999;
  }
  .results-menu ul {
    list-style: none;
    padding: 10px;
  }
  .result-link {
    font-family: "forma-djr-micro";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */

    /* Design/Blue */

    color: #007cc3;
  }
  @media screen and (max-width: 1079px) {
    .box-container {
      height: 68px;
      padding: 0 15px;
    }
    input {
      height: 18px;
      padding: 10px;
      font-family: "forma-djr-micro";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
    }
    #button-desktop {
      display: none;
    }
    #button-mobile {
      display: grid;
      place-content: center;
    }
    svg#close-icon {
      display: none;
    }
    input {
      max-width: 100%;
    }
    .results-menu {
      bottom: 0px
    }
    .stock-quote span i {
      margin: 0 5px;
      transform: scale(0.9);
    }
  }
  @media screen and (max-width: 1079px) {
    .results-menu {
      width: 100vw;
    }
  }
</style>
