<svelte:options tag="cnrl-mega-menu" />

<script lang="ts">
  import { onMount } from "svelte";
  import { megaPosts } from "./store";
  import { api } from "./api";
  import type { WordpressPost } from "./global";

  let post: WordpressPost;
  export let slug = "";
  const getPost = async (slug: string) => {
    const resource = "pages";
    const getPost = $megaPosts.find((post) => post.slug === slug);
    if (getPost) {
      post = getPost;
    } else {
      const data = await api({ slug, resource });
      megaPosts.update((posts) => [...posts, data]);
      post = data;
    }
    // console.log(" meg menu studyData: ",  studyData)
  };

  onMount(() => {
    const parseSlug = slug.split("#").join("");
    // console.log("parsed slug", parseSlug)
    window.addEventListener("load", () => {
      getPost(parseSlug);
    });
  });

  // $: {
  //   console.log('post changed to ', post)
  // }
</script>

<main>
  {#if post}
    <img src={post.img} alt="" loading="lazy" />
    <div class="mega-post-content">{@html post.content}</div>
  {/if}
</main>

<style>
  main {
    width: 282px;
    height: 457px;
  }
  main img {
    width: 100%;
    width: 282px;
    height: 260px;
    object-fit: cover;
    border-radius: 15px;
  }
  .mega-post-content {
    font-family: "forma-djr-micro";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* or 130% */

    /* Design/Black */

    color: #000000;
  }
</style>
